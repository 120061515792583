:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: "Poppins", serif;
}

a {
  text-decoration-line: none;
  color: rgb(101, 131, 254);
}

body {
  line-height: 1.35 !important;
  font-family: "Poppins", serif;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

input {
  font-family: "Poppins", serif !important;
  ::placeholder {
    font-family: "Poppins", serif !important;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
}

#font {
  font-family: "Poppins", serif;
}

.content {
  background-color: #f4f7fe;
  color: #3e4246;
}
.confirm-button {
  background-color: transparent !important;
  color: #36b37e !important;
  margin-right: 50px;
}
.cancel-button {
  background-color: transparent !important;
  color: #ff5630 !important;
  margin-left: 50px;
}
.swal-container-z-index {
  z-index: 9999 !important;
}
.container-scroll {
  height: 20vh;
  width: "100%";

  overflow-y: auto;
  overflow-x: hidden;
}
.link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

@media (max-width: 360px) {
  .confirm-button {
    margin-right: 0px;
  }
  .cancel-button {
    margin-left: 0px;
  }
}
